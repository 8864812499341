.lia-video-player-icon-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--lia-bs-gray-300);
  opacity: 0.8;
  width: 70px;
  height: 70px;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  &:after {
    content: '';
    mask-image: var(--lia-video-play-disabled-icon);
    background-color: var(--lia-bs-gray-600);
    width: 16px;
    height: 16px;
  }
}

.lia-video-player-icon-card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--lia-bs-gray-300);
  opacity: 0.8;
  width: 70px;
  height: 70px;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  &:after {
    content: '';
    mask-image: var(--lia-video-play-disabled-icon);
    background-color: var(--lia-bs-gray-600);
    width: 16px;
    height: 16px;
  }
}

.lia-image-processing-preview {
  position: relative;
  width: 100%;
  height: 200px;
  max-width: 400px;
  background-color: var(--lia-bs-gray-600);
}

.lia-preview-wrapper {
  position: relative;
  display: inline;
}

.lia-img-preview-inline {
  position: relative;
  height: 200px;
  width: 100%;
  max-width: 400px;
  margin: 10px 5px 5px 0;
  object-fit: cover;
  border-radius: var(--lia-bs-border-radius-sm);
}

.lia-img-preview-card {
  position: relative;
  height: 200px;
  width: 100%;
  object-fit: cover;
}
